import axios from 'axios'
import Error from '@/libs/core/Error'
import Vault from '@/libs/core/Vault'

export default class Form {
  constructor (fields) {
    this.fields = fields
    this.errors = new Error(fields)
  }

  submit (method, url, data = {}) {
    const { client, token } = Vault.peek()
    const prefix = client ? `farmers/${client.client_uid}/` : ''
    return new Promise((resolve, reject) => {
      axios({
        baseURL: process.env.VUE_APP_FARMER_BFF,
        method, 
        url: `${prefix}${url}`, 
        data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(({ data }) => {
          resolve(data)
        }).catch(({ response }) => {
          this.errors.record(response.data)
          reject(response.data)
        })
    })
  }
}