import Base from "@/libs/core/Base"
import Vault from "@/libs/core/Vault"

const fields = {
  entity_type: null,
  name: null,
  contact_person: {
    name: null,
    phone: null,
    password: null,
  },
}

export default class Auth extends Base {
  constructor() {
    super(fields)
  }

  register() {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.getFields()
        data.contact_person.phone = data.contact_person.phone.replaceAll(
          /[ +]/g,
          "",
        )
        let response = await this.form.submit("post", "iam/register", data)
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }

  login() {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.contact_person
        data.phone = data.phone.replaceAll(/[ +]/g, "")
        let response = await this.form.submit("post", "iam/login", data)
        Vault.keep(response.data)
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }

  logout() {
    localStorage.removeItem(this.app)
  }
}

